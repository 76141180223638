.Header {
  position: fixed;
  height: 80px;
  width: 100%;
  z-index: 1;

  border-bottom: 1px solid var(--lightGrey);
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 1rem;
  background: white;
}

.Header-account {
  display: flex;
  gap: 1rem;
}

.Header-button {
  align-self: center;
}

.Header-username {
  color: var(--darkGrey);
}

.Footer {
  border-top: 1px solid var(--lightGrey);
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  padding: 2rem;
  background: white;
}

.Footer-contact p {
  font-size: 15px;
}