@font-face {
  font-family: 'Eina01';
  font-display: swap;
  src: url('../fonts/Eina01-Regular.woff2') format('woff2'),
       url('../fonts/Eina01-Regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Eina01';
  font-display: swap;
  src: url('../fonts/Eina01-SemiBold.woff2') format('woff2'),
       url('../fonts/Eina01-SemiBold.woff') format('woff');
  font-weight: 500;
  font-style: normal;
}

*, *::before, *::after {
  box-sizing: border-box;
  position: relative;
  margin: 0;
  padding: 0;
}

:root {
  --sand: #FBF8F6;
  --green: #77D99E;
  --blue: #DCECFF;

  --darkGrey: #353B3F;
  --mediumGrey: #667884;
  --lightGrey: #BDC4C9;

  --darkBlue: #0077C7;
  --darkGreen:#005A2E;
  --darkRed: #E4002F;
}

html {
  font-family: Eina01, sans-serif;
  font-size: 16px;
  -webkit-font-smoothing: antialiased;
}

body {
  display: flex;
  flex-direction: column;
  height: 100vh;
  width: 100vw;
  background: var(--sand);
}

a {
  cursor: pointer;
  color: var(--darkBlue);
}

h1,h2,h3,h4,h5,h6 {
  margin-bottom: 1.5rem;
}

button {
  font: inherit;
  font-size: 15px;
  font-weight: 600;
  background: black;
  color: white;
  cursor: pointer;
  
  line-height: 40px;
  height: 40px;
  border: none;
  border-radius: 20px;
  padding: 0 24px;

  -webkit-appearance: none; /* 1 */
  appearance: none;
}

button:disabled {
  opacity: .5;
  cursor: not-allowed;
}

.Button--secondary {
  border: 2px solid black;
  background: transparent;
  color: black;
  box-sizing: content-box;
  border-radius: 22px;
}

.Button--red {
  background: var(--darkRed);
}

.Button--red:disabled {
  background: var(--li)
}

.Button--iconright {
  padding-right: 8px;
}

.Button-content {
  display: flex;
  align-items: center;
  gap: 8px;
}