.SubscriptionDetails-card {
  overflow: hidden;
  background: white;
  border-radius: 8px;
  margin-bottom: 2rem;
  --shadow-color: 0deg 0% 0%;
  box-shadow: 0px 0.6px 0.7px hsl(var(--shadow-color) / 0.07),
    0px 2.7px 3px -0.5px hsl(var(--shadow-color) / 0.07),
    0.1px 5.3px 5.9px -1px hsl(var(--shadow-color) / 0.07),
    0.1px 10px 11.1px -1.6px hsl(var(--shadow-color) / 0.07),
    0.3px 18.2px 20.2px -2.1px hsl(var(--shadow-color) / 0.06),
    0.4px 31.5px 35px -2.6px hsl(var(--shadow-color) / 0.06);
}

.SubscriptionDetails-cardheader {
  background: var(--darkBlue);
  color: white;
  padding: 8px 20px;
  text-transform: uppercase;
  margin-bottom: 0;
  font-weight: 600;
}

.SubscriptionDetails-cardbody {
  padding: 20px;
}

.SubscriptionDetails-table {
  border-collapse: collapse;
}

.SubscriptionDetails-table td {
  padding: .5rem 1rem .5rem .5rem;
}

.SubscriptionDetails-table td:first-child {
  font-weight: 600;
}

.SubscriptionDetails-cta {
  margin-right: 1rem;
}

.SubscriptionDetails-error {
  margin-left: 2rem;
}