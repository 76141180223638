.Main {
  flex-grow: 1;


  max-width: min(100%, 1000px);
  min-width: min(100%, 800px);

  margin: 0 auto;
  padding: 2rem 1rem;

  margin-top: 80px;
}

.Main p {
  margin-bottom: 1.5rem;
}

.Main-centerwrap {
  display: flex;
  align-items: center;
  height: 100%;
}

